.text {
  user-select: none;
  letter-spacing: 0;
  font-size: 20px;
  line-height: normal;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  white-space: pre-line;
  box-sizing: border-box;
  flex-shrink: 0;
}

.text-body-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-body {
  font-family: Noto Sans JP;
  margin: 0;
  vertical-align: middle;
}

.text-font-size-h1 {
  font-size: 40px;
}
.text-font-size-h2 {
  font-size: 32px;
}
.text-font-size-h3 {
  font-size: 28px;
}
.text-font-size-body1 {
  font-size: 24px;
}
.text-font-size-body2 {
  font-size: 20px;
}
.text-font-size-body2-narrow {
  font-size: 20px;
  line-height: 22px;
}
.text-font-size-caption1 {
  font-size: 18px;
}
.text-font-size-caption1-narrow {
  font-size: 18px;
  line-height: 20px;
}
.text-font-caption2 {
  font-size: 16px;
}
.text-font-caption2-narrow {
  font-size: 16px;
  line-height: 18px;
}

.input {
  width: 100%;
  height: 100%;
  user-select: text;
  border: 4px solid #c2c9cf;
  border-radius: 12px;
  background-color: #fefdfe;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
}
.input:focus {
  border-color: rgba(112, 177, 244, 0.4);
}
.input::placeholder {
  color: #e0e4e7;
}
.input:read-only {
  background-color: #c9c4c8;
}
.input:invalid {
  border-color: #fc6560;
}

.input-modal {
  width: 100%;
  height: 100%;
  user-select: text;
  border: 4px solid #c2c9cf;
  border-radius: 12px;
  background-color: #fefdfe;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
}
.input-modal:focus {
  border-color: rgba(112, 177, 244, 0.4);
}
.input-modal::placeholder {
  color: #e0e4e7;
}
.input-modal:read-only {
  background-color: #c9c4c8;
}
