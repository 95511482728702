.opacity-button {
  cursor: pointer;
  border: none;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  box-sizing: border-box;
  flex-basis: auto;
  border-radius: 10px;
  padding: 0;
  background-color: transparent;
}

.opacity-button p {
  letter-spacing: 0 !important;
  font-family: "MPLUSRounded1c-Bold" !important;
}

.opacity-button:enabled:active {
  opacity: 0.7;
}

.opacity-button:disabled {
  background-color: #f2eff1 !important;
}

.opacity-action-button {
  border: none;
  position: relative;
  box-sizing: border-box;
}

.opacity-action-button:enabled:active {
  opacity: 0.7;
}

.opacity-action-button:disabled {
  background-color: #f2eff1 !important;
}

.clickable {
  cursor: pointer;
  transition: box-shadow 0.2s, transform 0.2s, color 0.2s;
}
.clickable:active {
  transform: translateY(3px);
}

.physical .btn {
  display: block;
  border-width: 0;
  border-style: solid;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s, box-shadow 0.2s;
  background: linear-gradient(180deg, #ffffff 41.15%, #e6e5e6 100%);
  box-shadow: 0px 2px 0px 0px rgba(51, 51, 51, 0.15),
    2.231578709155195e-16px 4px 4px 0px rgba(255, 255, 255, 0.8) inset,
    -2.231578709155195e-16px -4px 4px 0px #d6d5d6 inset,
    -1px -1px 1px 0px rgba(0, 0, 0, 0.1), 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
}
.physical .btn:active {
  transition-duration: 0.1s;
  background: linear-gradient(180deg, #ffffff 0%, #f2f1f2 100%);
  box-shadow: 0px 2px 2px 0px rgba(51, 51, 51, 0.15),
    -1px -1px 1px 0px rgba(0, 0, 0, 0.1), 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
}
.physical .btn:disabled {
  background: linear-gradient(180deg, #ffffff 0%, #f2f1f2 100%);
  box-shadow: 0px 2px 2px 0px rgba(51, 51, 51, 0.15),
    -1px -1px 1px 0px rgba(0, 0, 0, 0.1), 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
}

.alpha .btn {
  display: block;
  margin: 6px;
  padding: 0.8em 1.6em;
  border-width: 0;
  border-style: solid;
  border-radius: 12px;
  cursor: pointer;
  transition: box-shadow 0.2s, transform 0.2s, color 0.2s;
}
.alpha .btn:active {
  transform: translateY(3px);
}
.alpha .btn-primary {
  box-shadow: 0 3px 0 0 #167752, 0 4px 4px -1px rgba(0, 0, 0, 0.6),
    0 4px 6px 1px rgba(0, 0, 0, 0.3), 0 1px 2px 1px rgba(0, 0, 0, 0) inset,
    0 18px 32px -2px rgba(255, 255, 255, 0.1) inset;
  background-color: #2bdc98;
  background-image: linear-gradient(-45deg, #2bdc98, #2bdc98);
  color: #e6eaef;
}
.alpha .btn-primary:active {
  box-shadow: 0 0px 0 0 #2d6911, 0 3px 0 0 rgba(0, 0, 0, 0),
    0 4px 16px rgba(0, 0, 0, 0), 0 1px 2px 1px rgba(0, 0, 0, 0.5) inset,
    0 -18px 32px -2px rgba(255, 255, 255, 0.1) inset;
  transition: 0s;
  color: #367f14;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.3);
}
.alpha .btn-primary:disabled {
  background-color: #e0e4e7;
  background-image: linear-gradient(-45deg, #e0e4e7, #e0e4e7);
  transform: unset;
  box-shadow: unset;
  text-shadow: unset;
}

.alpha .btn-blue {
  box-shadow: 0 3px 0 0 #516d8a, 0 4px 4px -1px rgba(0, 0, 0, 0.6),
    0 4px 6px 1px rgba(0, 0, 0, 0.3), 0 1px 2px 1px rgba(0, 0, 0, 0) inset,
    0 18px 32px -2px rgba(255, 255, 255, 0.1) inset;
  background-color: #70b1f4;
  background-image: linear-gradient(-45deg, #70b1f4, #70b1f4);
  color: #fefdfe;
}
.alpha .btn-blue:active {
  box-shadow: 0 0px 0 0 #3a4f65, 0 3px 0 0 rgba(0, 0, 0, 0),
    0 4px 16px rgba(0, 0, 0, 0), 0 1px 2px 1px rgba(0, 0, 0, 0.5) inset,
    0 -18px 32px -2px rgba(255, 255, 255, 0.1) inset;
  transition: 0s;
  color: #fefdfe;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.3);
}
.alpha .btn-blue:disabled {
  opacity: 0.6;
  background-color: #e6eaef;
  background-image: unset;
  color: #5d7592;
  box-shadow: unset;
  transform: unset;
}

.alpha .btn-danger {
  box-shadow: 0 3px 0 0 #b64643, 0 4px 4px -1px rgba(0, 0, 0, 0.6),
    0 4px 6px 1px rgba(0, 0, 0, 0.3), 0 1px 2px 1px rgba(0, 0, 0, 0) inset,
    0 18px 32px -2px rgba(255, 255, 255, 0.1) inset;
  background-color: #fc6560;
  background-image: linear-gradient(-45deg, #fc6560, #fc6560);
  color: #fefdfe;
}
.alpha .btn-danger:active {
  box-shadow: 0 0px 0 0 #913835, 0 3px 0 0 rgba(0, 0, 0, 0),
    0 4px 16px rgba(0, 0, 0, 0), 0 1px 2px 1px rgba(0, 0, 0, 0.5) inset,
    0 -18px 32px -2px rgba(255, 255, 255, 0.1) inset;
  transition: 0s;
  color: #913835;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.3);
}
.alpha .btn-danger:disabled {
  background-color: #e0e4e7;
  background-image: linear-gradient(-45deg, #e0e4e7, #e0e4e7);
  transform: unset;
  box-shadow: unset;
  text-shadow: unset;
}

.gamma .btn {
  display: block;
  margin: 6px;
  line-height: 1.42;
  padding: 0.3em 1.1em;
  border-width: 1px;
  border-style: solid;
  border-radius: 1.01em;
  cursor: pointer;
  user-select: none;
  box-shadow: 0 1px 1px 0 rgba(255, 255, 255, 0.2) inset;
}
.gamma .btn-primary {
  color: #e6eaef;
  background-color: #2bdc98;
  background-image: linear-gradient(-45deg, #2bdc98, #2bdc98);
  border-color: #24bc82;
}
.gamma .btn-primary:active {
  background-color: #26c588;
  background-image: linear-gradient(-45deg, #24c084, #24b980);
  box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.4) inset;
}
.gamma .btn-primary:disabled {
  opacity: 0.6;
  background-color: #e0e4e7;
  background-image: linear-gradient(-45deg, #e0e4e7, #e0e4e7);
  box-shadow: unset;
}
.gamma .btn-secondary {
  color: #e6eaef;
  background-color: #94c4f7;
  background-image: linear-gradient(0deg, #94c4f7, #a0c8f3);
  border-color: #80a9d6;
}
.gamma .btn-secondary:active {
  background-color: #79a3cf;
  background-image: linear-gradient(0deg, #80abda, #85b2e2);
  box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.4) inset;
}
.gamma .btn-secondary:disabled {
  opacity: 0.6;
  border-color: #e6eaef;
  box-shadow: unset;
  background-color: #e6eaef;
  background-image: unset;
  color: #5d7592;
}

.rho .btn {
  display: block;
  margin: 6px;
  padding: 0.6em 2.2em;
  border-width: 0;
  border-style: solid;
  border-radius: 99999em;
  cursor: pointer;
  transition: box-shadow 0.2s, background-color 0.2s;
  position: relative;
  color: #3f4351;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3), 0 1px 4px rgba(0, 0, 0, 0.3),
    0 2px 7px rgba(0, 0, 0, 0.4);
}
.rho .btn:before {
  content: "";
  display: block;
  position: absolute;
  padding: 0.8em;
  text-shadow: none;
  top: -0.8em;
  left: -0.8em;
  right: -0.8em;
  bottom: -0.8em;
  border-radius: 99999em;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2) inset,
    0 3px 3px -2px rgba(0, 0, 0, 0.2) inset;
  z-index: -1;
}
.rho .btn-secondary {
  background: linear-gradient(180deg, #ffae40 0%, #e18d34 100%);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 1px 4px rgba(0, 0, 0, 0.1),
    0 2px 7px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.1) inset;
}
.rho .btn-secondary:active {
  background: linear-gradient(180deg, #e89f39 0%, #a26523 100%);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 1px 4px rgba(0, 0, 0, 0.1),
    0 2px 7px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.1) inset;
}
.rho .btn-secondary:before {
  background-color: #fefdfe;
}

.upsilon .btn {
  display: block;
  margin: 6px;
  padding: 0.4em 1em;
  border-width: 0;
  border-style: solid;
  background: transparent;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.2s, box-shadow 0.2s, transform 0.2s;
  box-shadow: 0 -2px 0 0 rgba(0, 0, 0, 0.3) inset;
}
.upsilon .btn:active {
  transition-duration: 0.1s;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3) inset;
  transform: translateY(2px);
}
.upsilon .btn:disabled {
  opacity: 0.8;
  background-color: #e6eaef !important;
  color: #5d7592;
  box-shadow: unset;
  transform: unset;
}
.upsilon .btn-primary {
  background-color: #2bdc98;
  color: #fefdfe;
}
.upsilon .btn-primary:active {
  background-color: #25c88a;
}
.upsilon .btn-primary-outlined {
  background-color: #fefdfe;
  color: #2bdc98;
}
.upsilon .btn-primary-outlined:active {
  background-color: #fefdfe;
  color: #25c88a;
}
.upsilon .btn-primary-outlined-active {
  background-color: #2bdc98;
  color: #fefdfe;
  transition-duration: 0.1s;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3) inset;
  transform: translateY(1px);
}
.upsilon .btn-secondary {
  background-color: #ffa03b;
  color: #fefdfe;
}
.upsilon .btn-secondary:active {
  background-color: #e99235;
}
.upsilon .btn-blue {
  background-color: #94c4f7;
  color: #fefdfe;
}
.upsilon .btn-blue-outlined {
  background-color: #fefdfe;
  color: #94c4f7;
}
.upsilon .btn-blue-outlined:active {
  background-color: #fefdfe;
  color: #79a2ce;
}
.upsilon .btn-blue-outlined-active {
  background-color: #94c4f7;
  color: #fefdfe;
  transition-duration: 0.1s;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3) inset;
  transform: translateY(1px);
}
.upsilon .btn-white {
  background-color: #fefdfe;
}
.upsilon .btn-white:active {
  background-color: #dbd9db;
}
.upsilon .btn-gray {
  background-color: #dbd8da;
}
.upsilon .btn-gray:active {
  background-color: #c2bec1;
}
.upsilon .btn-danger {
  background-color: #fc6560;
  color: #fefdfe;
}
.upsilon .btn-danger:active {
  background-color: #ea5d58;
  color: #fefdfe;
}
.upsilon .btn-danger-outlined {
  background-color: #fefdfe;
  color: #fc6560;
}
.upsilon .btn-danger-outlined:active {
  background-color: #fefdfe;
  color: #ea5d58;
}
.upsilon .btn-danger-outlined-active {
  background-color: #fc6560;
  color: #fefdfe;
  transition-duration: 0.1s;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3) inset;
  transform: translateY(1px);
}

.upsilon-square .btn {
  display: block;
  margin: 6px;
  padding: 0.4em;
  border-width: 0;
  border-style: solid;
  background: transparent;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.2s, box-shadow 0.2s, transform 0.2s;
  box-shadow: 0 -2px 0 0 rgba(0, 0, 0, 0.3) inset;
}
.upsilon-square .btn:active {
  transition-duration: 0.1s;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3) inset;
  transform: translateY(1px);
}
.upsilon-square .btn:disabled {
  opacity: 0.8;
  background-color: #f2eff1 !important;
  box-shadow: unset;
  transform: unset;
}
.upsilon-square .btn-primary {
  background-color: #2bdc98;
  color: #fefdfe;
}
.upsilon-square .btn-primary:hover {
  background-color: #2deba3;
}
.upsilon-square .btn-primary:active {
  background-color: #25bd83;
}
.upsilon-square .btn-secondary {
  background-color: #ffa03b;
  color: #fefdfe;
}
.upsilon-square .btn-secondary:hover {
  background-color: #ffa748;
}
.upsilon-square .btn-secondary:active {
  background-color: #e99235;
}
.upsilon-square .btn-blue {
  background-color: #94c4f7;
  color: #fefdfe;
}
.upsilon-square .btn-blue:hover {
  background-color: #9acbff;
}
.upsilon-square .btn-blue:active {
  background-color: #79a2ce;
}
.upsilon-square .btn-gray {
  background-color: #dbd8da;
}
.upsilon-square .btn-gray:hover {
  background-color: #f0ecee;
}
.upsilon-square .btn-gray:active {
  background-color: #c2bec1;
}
.upsilon-square .btn-danger {
  background-color: #fc6560;
}
.upsilon-square .btn-danger:hover {
  background-color: #ff6e69;
}
.upsilon-square .btn-danger:active {
  background-color: #ea5d58;
}

.upsilon-circle .btn {
  display: block;
  margin: 6px;
  padding: 1.2em;
  border-width: 0;
  border-style: solid;
  background: transparent;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.2s, box-shadow 0.2s, transform 0.2s;
  box-shadow: 0 -2px 0 0 rgba(0, 0, 0, 0.3) inset;
}
.upsilon-circle .btn:active {
  transition-duration: 0.1s;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3) inset;
  transform: translateY(1px);
}
.upsilon-circle .btn:disabled {
  opacity: 0.8;
  background-color: #f2eff1 !important;
  box-shadow: unset;
  transform: unset;
}
.upsilon-circle .btn-danger {
  background-color: #fc6560;
}
.upsilon-circle .btn-danger:hover {
  background-color: #ff6e69;
}
.upsilon-circle .btn-danger:active {
  background-color: #ea5d58;
}

.phi .btn {
  display: block;
  margin: 6px;
  padding: 0.8em 0.8em;
  border-width: 3px;
  border-style: solid;
  background: #fefdfe;
  border-radius: 0.4em;
  cursor: pointer;
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    box-shadow 0.3s, background-color 0.3s;
  border-radius: 0.5em 0.6em/0.6em 1em 3em 1em;
}
.phi .btn:hover {
  transform: translate(-2px, -2px);
}
.phi .btn:active {
  transition-duration: 0.1s;
  transform: translate(7px, 7px);
}
.phi .btn-vivid {
  color: #4865bf;
  border-color: #4865bf;
  box-shadow: 12px 0 0 0 #4865bf, 12px 12px 0 0 #4865bf, 0 12px 0 0 #4865bf;
}
.phi .btn-vivid:hover {
  box-shadow: 14px 0 0 0 #4865bf, 14px 14px 0 0 #4865bf, 0 14px 0 0 #4865bf;
}
.phi .btn-vivid:active {
  background-color: #4865bf;
  box-shadow: 8px 0 0 0 #4865bf, 8px 8px 0 0 #4865bf, 0 8px 0 0 #4865bf;
}

.omega .btn {
  display: block;
  margin: 6px;
  padding: 0.8em 1.6em;
  border-width: 2px;
  border-style: solid;
  background: transparent;
  border-radius: 0;
  cursor: pointer;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: #3f4351;
  border-color: #3f4351;
  position: relative;
}
.omega .btn:hover:before {
  transform: scale(0.85, 1.1) translate(8%, -15%);
}
.omega .btn:active:before {
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform: scale(1.04, 1.1) translate(6%, 3%);
}
.omega .btn:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: scale(0.4, 0.15) translate(12%, -20%);
  transform-origin: -5% -5%;
  transition: transform 0.3s, background-color 0.3s;
  z-index: -1;
}
.omega .btn-tertiary:hover {
  color: #fefdfe;
}
.omega .btn-tertiary:before {
  background-color: #ff006a;
}
