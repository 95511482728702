.dots-loader:not(:required) {
  overflow: hidden;
  position: relative;
  text-indent: -9999px;
  display: inline-block;
  width: 7px;
  height: 7px;
  background: transparent;
  border-radius: 100%;
  -moz-box-shadow: #f86 -14px -14px 0 7px, #fc6 14px -14px 0 7px,
    #6d7 14px 14px 0 7px, #4ae -14px 14px 0 7px;
  -webkit-box-shadow: #f86 -14px -14px 0 7px, #fc6 14px -14px 0 7px,
    #6d7 14px 14px 0 7px, #4ae -14px 14px 0 7px;
  box-shadow: #f86 -14px -14px 0 7px, #fc6 14px -14px 0 7px,
    #6d7 14px 14px 0 7px, #4ae -14px 14px 0 7px;
  -moz-animation: dots-loader 5s infinite ease-in-out;
  -webkit-animation: dots-loader 5s infinite ease-in-out;
  animation: dots-loader 5s infinite ease-in-out;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

@keyframes dots-loader {
  0% {
    -moz-box-shadow: #f86 -14px -14px 0 7px, #fc6 14px -14px 0 7px,
      #6d7 14px 14px 0 7px, #4ae -14px 14px 0 7px;
    -webkit-box-shadow: #f86 -14px -14px 0 7px, #fc6 14px -14px 0 7px,
      #6d7 14px 14px 0 7px, #4ae -14px 14px 0 7px;
    box-shadow: #f86 -14px -14px 0 7px, #fc6 14px -14px 0 7px,
      #6d7 14px 14px 0 7px, #4ae -14px 14px 0 7px;
  }
  8.33% {
    -moz-box-shadow: #f86 14px -14px 0 7px, #fc6 14px -14px 0 7px,
      #6d7 14px 14px 0 7px, #4ae -14px 14px 0 7px;
    -webkit-box-shadow: #f86 14px -14px 0 7px, #fc6 14px -14px 0 7px,
      #6d7 14px 14px 0 7px, #4ae -14px 14px 0 7px;
    box-shadow: #f86 14px -14px 0 7px, #fc6 14px -14px 0 7px,
      #6d7 14px 14px 0 7px, #4ae -14px 14px 0 7px;
  }
  16.67% {
    -moz-box-shadow: #f86 14px 14px 0 7px, #fc6 14px 14px 0 7px,
      #6d7 14px 14px 0 7px, #4ae -14px 14px 0 7px;
    -webkit-box-shadow: #f86 14px 14px 0 7px, #fc6 14px 14px 0 7px,
      #6d7 14px 14px 0 7px, #4ae -14px 14px 0 7px;
    box-shadow: #f86 14px 14px 0 7px, #fc6 14px 14px 0 7px, #6d7 14px 14px 0 7px,
      #4ae -14px 14px 0 7px;
  }
  25% {
    -moz-box-shadow: #f86 -14px 14px 0 7px, #fc6 -14px 14px 0 7px,
      #6d7 -14px 14px 0 7px, #4ae -14px 14px 0 7px;
    -webkit-box-shadow: #f86 -14px 14px 0 7px, #fc6 -14px 14px 0 7px,
      #6d7 -14px 14px 0 7px, #4ae -14px 14px 0 7px;
    box-shadow: #f86 -14px 14px 0 7px, #fc6 -14px 14px 0 7px,
      #6d7 -14px 14px 0 7px, #4ae -14px 14px 0 7px;
  }
  33.33% {
    -moz-box-shadow: #f86 -14px -14px 0 7px, #fc6 -14px 14px 0 7px,
      #6d7 -14px -14px 0 7px, #4ae -14px -14px 0 7px;
    -webkit-box-shadow: #f86 -14px -14px 0 7px, #fc6 -14px 14px 0 7px,
      #6d7 -14px -14px 0 7px, #4ae -14px -14px 0 7px;
    box-shadow: #f86 -14px -14px 0 7px, #fc6 -14px 14px 0 7px,
      #6d7 -14px -14px 0 7px, #4ae -14px -14px 0 7px;
  }
  41.67% {
    -moz-box-shadow: #f86 14px -14px 0 7px, #fc6 -14px 14px 0 7px,
      #6d7 -14px -14px 0 7px, #4ae 14px -14px 0 7px;
    -webkit-box-shadow: #f86 14px -14px 0 7px, #fc6 -14px 14px 0 7px,
      #6d7 -14px -14px 0 7px, #4ae 14px -14px 0 7px;
    box-shadow: #f86 14px -14px 0 7px, #fc6 -14px 14px 0 7px,
      #6d7 -14px -14px 0 7px, #4ae 14px -14px 0 7px;
  }
  50% {
    -moz-box-shadow: #f86 14px 14px 0 7px, #fc6 -14px 14px 0 7px,
      #6d7 -14px -14px 0 7px, #4ae 14px -14px 0 7px;
    -webkit-box-shadow: #f86 14px 14px 0 7px, #fc6 -14px 14px 0 7px,
      #6d7 -14px -14px 0 7px, #4ae 14px -14px 0 7px;
    box-shadow: #f86 14px 14px 0 7px, #fc6 -14px 14px 0 7px,
      #6d7 -14px -14px 0 7px, #4ae 14px -14px 0 7px;
  }
  58.33% {
    -moz-box-shadow: #f86 -14px 14px 0 7px, #fc6 -14px 14px 0 7px,
      #6d7 -14px -14px 0 7px, #4ae 14px -14px 0 7px;
    -webkit-box-shadow: #f86 -14px 14px 0 7px, #fc6 -14px 14px 0 7px,
      #6d7 -14px -14px 0 7px, #4ae 14px -14px 0 7px;
    box-shadow: #f86 -14px 14px 0 7px, #fc6 -14px 14px 0 7px,
      #6d7 -14px -14px 0 7px, #4ae 14px -14px 0 7px;
  }
  66.67% {
    -moz-box-shadow: #f86 -14px -14px 0 7px, #fc6 -14px -14px 0 7px,
      #6d7 -14px -14px 0 7px, #4ae 14px -14px 0 7px;
    -webkit-box-shadow: #f86 -14px -14px 0 7px, #fc6 -14px -14px 0 7px,
      #6d7 -14px -14px 0 7px, #4ae 14px -14px 0 7px;
    box-shadow: #f86 -14px -14px 0 7px, #fc6 -14px -14px 0 7px,
      #6d7 -14px -14px 0 7px, #4ae 14px -14px 0 7px;
  }
  75% {
    -moz-box-shadow: #f86 14px -14px 0 7px, #fc6 14px -14px 0 7px,
      #6d7 14px -14px 0 7px, #4ae 14px -14px 0 7px;
    -webkit-box-shadow: #f86 14px -14px 0 7px, #fc6 14px -14px 0 7px,
      #6d7 14px -14px 0 7px, #4ae 14px -14px 0 7px;
    box-shadow: #f86 14px -14px 0 7px, #fc6 14px -14px 0 7px,
      #6d7 14px -14px 0 7px, #4ae 14px -14px 0 7px;
  }
  83.33% {
    -moz-box-shadow: #f86 14px 14px 0 7px, #fc6 14px -14px 0 7px,
      #6d7 14px 14px 0 7px, #4ae 14px 14px 0 7px;
    -webkit-box-shadow: #f86 14px 14px 0 7px, #fc6 14px -14px 0 7px,
      #6d7 14px 14px 0 7px, #4ae 14px 14px 0 7px;
    box-shadow: #f86 14px 14px 0 7px, #fc6 14px -14px 0 7px,
      #6d7 14px 14px 0 7px, #4ae 14px 14px 0 7px;
  }
  91.67% {
    -moz-box-shadow: #f86 -14px 14px 0 7px, #fc6 14px -14px 0 7px,
      #6d7 14px 14px 0 7px, #4ae -14px 14px 0 7px;
    -webkit-box-shadow: #f86 -14px 14px 0 7px, #fc6 14px -14px 0 7px,
      #6d7 14px 14px 0 7px, #4ae -14px 14px 0 7px;
    box-shadow: #f86 -14px 14px 0 7px, #fc6 14px -14px 0 7px,
      #6d7 14px 14px 0 7px, #4ae -14px 14px 0 7px;
  }
  100% {
    -moz-box-shadow: #f86 -14px -14px 0 7px, #fc6 14px -14px 0 7px,
      #6d7 14px 14px 0 7px, #4ae -14px 14px 0 7px;
    -webkit-box-shadow: #f86 -14px -14px 0 7px, #fc6 14px -14px 0 7px,
      #6d7 14px 14px 0 7px, #4ae -14px 14px 0 7px;
    box-shadow: #f86 -14px -14px 0 7px, #fc6 14px -14px 0 7px,
      #6d7 14px 14px 0 7px, #4ae -14px 14px 0 7px;
  }
}

$rum-width: 3;
$rum-height: 3;
$rum-rotation: 0.4;

.rum {
  animation: rumble 0.5s linear;
}

@keyframes rumble {
  0% {
    transform: rotate(0deg) translate(0, 0);
  }
  12.5% {
    transform: rotate($rum-rotation + 0deg)
      translate($rum-width + 0px, 0px - $rum-height);
  }
  25% {
    transform: rotate($rum-rotation * 2 + 0deg)
      translate(0px, $rum-height + 0px);
  }
  37.5% {
    transform: rotate($rum-rotation + 0deg) translate(0px - $rum-width, 0);
  }
  50% {
    transform: rotate(0deg) translate(0, 0);
  }
  62.5% {
    transform: rotate(0deg - $rum-rotation) translate($rum-width + 0px, 0);
  }
  75% {
    transform: rotate(0deg - $rum-rotation * 2) translate(0, $rum-height + 0px);
  }
  87.5% {
    transform: rotate(0deg - $rum-rotation)
      translate(0px - $rum-width, 0px - $rum-height);
  }
  100% {
    transform: rotate(0deg) translate(0, 0);
  }
}

.clear-stamp-animation {
  transform-origin: center center;
  animation: fuwafuwa 2s;
}
@keyframes fuwafuwa {
  0% {
    opacity: 0.5;
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes border-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wave {
  border-radius: 50% 50% 50% 70% / 50% 50% 70% 60%;
  animation: border-animation 20s infinite linear;
}

.modal-overlay.fade-in {
  animation: fadeIn 0.5s forwards;
}

.modal-overlay.fade-out {
  animation: fadeOut 0.5s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
